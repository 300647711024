import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// TODO: переехать на Gatsby Head API https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
// @ts-ignore

export function YandexTableau() {
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  return (
    <meta
      name="yandex-tableau-widget"
      content={`logo=${siteUrl}/logo-transparent.png, color=#ffffff`}
    />
  );
}

import type { ReactNode } from 'react';
import React from 'react';
import styles from './OneColumn.module.scss';

interface OneColumnArgs {
  children?: ReactNode;
}

export function OneColumn({ children }: OneColumnArgs) {
  return <div className={styles.block}>{children}</div>;
}
